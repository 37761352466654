import { NewsFeed } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import PrimaryButton from '~/src/components/mui-wrappers/buttons/PrimaryButton'
import Link from '~/src/components/mui-wrappers/Link'
import { useTranslations } from '~/src/hooks/useTranslations'
import routes from '~/src/router/enums/routes.enum'
import Title from '~/src/components/mui-wrappers/Title'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

type Props = {
  news: NewsFeed,
}

const ExplorerNewsItem = ({ news }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const { t } = useTranslations()

  return (
    <Box
      sx={{
        height: '100%',
        display: 'grid',
        gridTemplateAreas: `
          'explorer-news-item-image'
          'explorer-news-item-title'
          'explorer-news-item-description'
          'explorer-news-item-action'
        `,
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'repeat(2, max-content) minmax(100px, 10vh) max-content'
      }}>
      <Box
        component='img'
        src={news.image}
        sx={{
          maxWidth: '100%',
          gridArea: 'explorer-news-item-image'
        }}
      />
      <Title
        variant='h5'
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: 1,
          gridArea: 'explorer-news-item-title',
          [muiTheme.breakpoints.down('md')]: {
            px: 1
          }
        }}
        className={'eo-ellipsis'}
      >
        {news.title}
      </Title>

      <Typography
        component='div'
        gridArea={'explorer-news-item-description'}
        variant='body1'
        className={'eo-ellipsis'}
        dangerouslySetInnerHTML={{
          __html: news.content
        }}
        sx={{
          [muiTheme.breakpoints.down('md')]: {
            px: 1
          }
        }} />

      <Box sx={{
        gridArea: 'explorer-news-item-action',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        [muiTheme.breakpoints.down('md')]: {
          px: 1
        }
      }}>
        <Link
          removeBaseLinkStyle
          href={`${routes.NEWS}/${news.id}/details`}
          sx={{ pt: 2 }}
        >
          <PrimaryButton
            variant='outlined'
            sx={{
              color: 'primary.main',
              borderColor: 'primary.main',
              backgroundColor: 'background.main',
              borderWidth: '2px',
              ':hover': {
                backgroundColor: 'background.main',
                borderWidth: '2px',
                fontWeight: 'bold'
              }
            }}
          >
            {t('explorer.news.viewDetails')}
          </PrimaryButton>
        </Link>
      </Box>
    </Box>
  )
}

export default ExplorerNewsItem
