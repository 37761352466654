import { Location } from '@eo-storefronts/eo-core'
import SuggestedItem from '~/src/components/marketplace/suggestions/suggested-item'
import ScrollableList from '~/src/components/mui-wrappers/scrollable-list'
import ScrollableListItem from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-item'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  items: Location[],
}

const SuggestedItems = ({ items }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <ScrollableList orientation='horizontal'>
      {items.map((location: Location, index: number) =>
        <ScrollableListItem
          key={`${index}-suggested-item-${location.uid}`}
          index={index}
          sx={{
            [muiTheme.breakpoints.down('md')]: {
              pl: 0,
              pr: 1
            }
          }}
        >
          <SuggestedItem item={location} />
        </ScrollableListItem>
      )}
    </ScrollableList>
  )
}

export default SuggestedItems
